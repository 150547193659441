import Link from "next/link";
import styles from "./../styles/login.module.scss";
import FrontOfficeLayout from "../components/layouts/FrontOfficeLayout";
import { withRouter } from "next/router";
import { appRoutes, defaultRouteAfterLogin } from "../configs/routes";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useState } from "react";
import { toast } from "react-toastify";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import Navbar from "../components/navbar/Navbar";

const LoginPage = ({ router }) => {
  const path = router.query.next ? router.query.next : defaultRouteAfterLogin;

  // [TODO]: Disable "close" btn or don't show it again after manual dismiss
  if (router.query.next) {
    toast.warning(
      () => (
        <strong style={{ display: "block", textAlign: "center" }}>
          Veuillez vous connecter pour continuer
        </strong>
      ),
      {
        autoClose: false,
        toastId: "redirect-to-login-page-toast-id",
      }
    );
  }

  const { loginUser } = useStoreActions((actions) => actions.auth);
  const { isUserLoggedIn } = useStoreState((state) => state.auth);

  //oeil password
  const [isPwdVisible, setPwdVisible] = useState(false);
  const changePwdVisibility = () => {
    setPwdVisible((pwdVisibility) => !pwdVisibility);
  };

  const [inputs, setInputs] = useState({
    email: "",
    password: "",
  });

  const [attemptingToLogin, setAttemptingToLogin] = useState(false);
  const { currentUser } = useStoreState((state) => state.auth);

  // When the user is successfully loggin, redirect
  if (isUserLoggedIn) {
    toast.dismiss();
    router.replace(path);
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  };

  const onLogin = (e) => {
    e.preventDefault();
    setAttemptingToLogin(true);

    loginUser(inputs).then((email) => {
      if (email) {
        setAttemptingToLogin(true);
      } else {
        setAttemptingToLogin(false);
      }
    });

    //remove
  };

  return (
    <FrontOfficeLayout
      pageTitle="Connexion"
      pageDesc="Connectez-vous afin d'accéder à vos informations personnelles"
      pageUrl="https://www.regitorr.com/login"
      pageImg="https://www.regitorr.com/regitor-homepage-img.jpeg"
    >
      <div className="mb-2">
        <Navbar />
      </div>
      <main className={`${styles.LoginPage} main-with-p`}>
        <div className="row justify-content-center">
          <div className="mx-auto my-auto">
            <div className="row">
              <div className="card col-12">
                <div className="card-body">
                  <h1 className="mb-3 fw-600 heading-auth">Se connecter</h1>
                  <div className="mb-3 form-wrapper">
                    <form onSubmit={onLogin}>
                      <div className="form-group">
                        <label htmlFor="email" className="fw-600">
                          Email
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          name="email"
                          id="email"
                          placeholder="Saisissez votre mail"
                          required={true}
                          value={inputs.email}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="pwd" className="fw-600">
                          Mot de passe
                        </label>
                        <input
                          className="form-control"
                          type={isPwdVisible ? "text" : "password"}
                          name="password"
                          id="pwd"
                          placeholder="Saisissez votre passe"
                          required={true}
                          value={inputs.password}
                          onChange={handleInputChange}
                        />

                        <div
                          style={{
                            position: "absolute",
                            top: "52%",
                            right: 29,
                            cursor: "pointer",
                          }}
                          onClick={changePwdVisibility}
                        >
                          {isPwdVisible ? (
                            <FaRegEyeSlash style={{ width: 20, height: 20 }} />
                          ) : (
                            <FaRegEye
                              color="#fbc216"
                              style={{ width: 20, height: 20 }}
                            />
                          )}
                        </div>
                      </div>
                      <button
                        type="submit"
                        className="mt-4 btn plain-btn full-width"
                        disabled={
                          Object.values(inputs).some((input) => !input) ||
                          attemptingToLogin
                        }
                      >
                        {attemptingToLogin ? (
                          <div
                            className="spinner-border spinner-border-sm"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        ) : (
                          <span>Se connecter</span>
                        )}
                      </button>

                      <div className="login-issue">
                        <Link href={appRoutes.forgotPassword}>
                          <a>Mot de passe oublié?</a>
                        </Link>
                      </div>
                      <div className="or">&nbsp; Ou &nbsp;</div>
                      <div>
                        <Link as="/creer-mon-compte" href={appRoutes.register}>
                          <button className="goto-register-btn full-width">
                            Créer mon compte
                          </button>
                        </Link>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </FrontOfficeLayout>
  );
};

export default withRouter(LoginPage);
